<template>
    <code-editor
        v-if="openFilePaths.length > 0"
        :client="client"
        :project-name="projectName"
        :template-name="templateName"
        :file-explorer-root-path="`${projectName}/${mode}`"
        :open-file-paths="openFilePaths"
    />
</template>
<script>
import { TuttiClient } from '@iflb/tutti-client'
import CodeEditor from '@/components/views/CodeEditor'
export default {
    components: {
        CodeEditor
    },
    data() {
        return {
            client: new TuttiClient(true),
            wsdPath: '/ducts/wsd',

            projectName: this.$route.query.project_name,
            templateName: this.$route.query.template_name,

            openFilePaths: []
        }
    },
    props: ['mode'],
    methods: {
        async migrateSchemeIfOldFileExists() {
            const oldSchemeFilePath = `${this.projectName}/scheme.py`;
            const newSchemeFilePath = `${this.projectName}/scheme/main.py`;
            const { success, content: existingOldFiles } = await this.client._duct.call(
                    this.client._duct.EVENT.SYSTEM_FS_EXIST_FILES,
                    {
                        file_paths: [oldSchemeFilePath],
                        current_path: '',
                        source: 'projects'
                    }
                );
            if(success && existingOldFiles.length > 0) {
                await this.client._duct.call(
                        this.client._duct.EVENT.SYSTEM_FS_CREATE_FOLDER,
                        {
                            path: this.projectName,
                            name: 'scheme',
                            source: 'projects'
                        }
                    );
                await this.client._duct.call(
                        this.client._duct.EVENT.SYSTEM_FS_COPY_FILE,
                        {
                            current_path: '.defaultproject/scheme/__init__.py',
                            new_path: `${this.projectName}/scheme/__init__.py`,
                            source: 'projects'
                        }
                    );
                await this.client._duct.call(
                        this.client._duct.EVENT.SYSTEM_FS_RENAME_FILE,
                        {
                            current_path: oldSchemeFilePath,
                            new_path: newSchemeFilePath,
                            source: 'projects'
                        }
                    );
            }
            return newSchemeFilePath;
        },
        async getOpenFilePaths() {
            let paths = [];
            if(this.mode === 'scheme') {
                const newSchemeFilePath = await this.migrateSchemeIfOldFileExists();
                paths.push(newSchemeFilePath);
            } else if (this.mode === 'templates') {
                if(this.templateName === '[Preview]') {
                    paths.push(`${this.projectName}/templates/Preview.vue`);
                } else if(this.templateName === '[Instruction]') {
                    paths.push(`${this.projectName}/templates/Instruction.vue`);
                } else {
                    paths.push(`${this.projectName}/templates/${this.templateName}/Main.vue`);
                }
            }
            return paths;
        }
    },
    async mounted() {
        await this.client.open(this.wsdPath);

        this.openFilePaths = await this.getOpenFilePaths();
    }
}
</script>
<style>
</style>