<template>
    <v-app>
        <v-app-bar
            app
            fixed
            height="30"
            elevation="4"
            class="pa-0"
            extension-height="30"
        >
            <v-toolbar-items>
                <v-menu offset-y rounded="0" v-model="menuOpen.File">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" text class="text-none">File</v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="$refs.dialogFileExplorer.show()">Open...</v-list-item>
                        <v-list-item @click="saveFile">Save</v-list-item>
                        <v-list-item @click="confirmOrCloseTab(selectedTabIndex)">Close</v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y rounded="0" v-model="menuOpen.Edit">
                    <template #activator="{ attrs, on }">
                        <v-btn v-bind="attrs" v-on="on" text class="text-none">Edit</v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="execCMCommand('undo')">Undo</v-list-item>
                        <v-list-item @click="execCMCommand('redo')">Redo</v-list-item>
                        <v-list-item @click="execCMCommand('selectAll')">Select All</v-list-item>
                        <v-divider></v-divider>
                        <v-menu offset-x open-on-hover rounded="0">
                            <template #activator="{ attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on" style="cursor: default">
                                    <v-list-item-content class="pa-0">Color Theme</v-list-item-content>
                                    <v-list-item-action class="my-0">
                                        <v-icon
                                            small
                                        >mdi-chevron-right</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-for="themeKey,i in cmThemeItems"
                                    :key="`theme-list-${i}`"
                                    @click="setCMTheme(themeKey); menuOpen.Edit = false;"
                                >
                                    <v-list-item-content class="pa-0">{{ themeKey }}</v-list-item-content>
                                    <v-list-item-action class="ma-0">
                                        <v-icon
                                            v-if="cmAllOptions.theme === themeKey"
                                            small
                                        >mdi-check</v-icon>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-list-item @click="openSettingsTab">More Settings...</v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <div>Project: {{ projectName }}</div>

            <template #extension>
                <v-tabs show-arrows color="indigo" height="30" v-model="selectedTabIndex">
                    <v-tabs-slider color="indigo"></v-tabs-slider>

                    <draggable
                        class="d-flex"
                        :value="files"
                        @change="(e) => { focusTab(e.moved.newIndex) }"
                        @input="(l) => { files = l; }"
                    >
                        <v-tab v-for="file,i in files" :key="`file-tab-${i}`" style="cursor:initial">
                            <div class="d-flex" :style="{ minWidth: '100px', maxWidth: selectedTabIndex!==i ? '150px' : undefined }">
                                <div :class="{ 'text-none': true, 'text-truncate': selectedTabIndex!==i }">
                                    {{ file.label }}
                                </div>

                                <v-spacer></v-spacer>

                                <v-hover v-slot="{ hover }">
                                    <v-icon
                                        small
                                        class="ml-2"
                                        @click.stop="confirmOrCloseTab(i)"
                                    >
                                        {{ (file.saved || hover) ? 'mdi-close' : 'mdi-circle-medium' }}
                                    </v-icon>
                                </v-hover>
                            </div>
                        </v-tab>
                    </draggable>
                </v-tabs>
            </template>
        </v-app-bar>

        <v-main>
            <codemirror
                v-for="file,i in files"
                v-show="selectedTabIndex==i"
                :key="`cm-${file.path}`"
                :ref="`cm-${file.path}`"
                v-model="file.code"
                :options="cmAllOptions"
                @input="files[selectedTabIndex].saved = false;"
                @ready="(cm) => { cm.doc.clearHistory(); cm.refresh(); }"
            ></codemirror>
        </v-main>

        <tutti-snackbar ref="snackbar" />

        <tutti-dialog
            ref="dialogcloseTabAlert"
            max-width="600"
            :buttons="[
                {
                    label: 'Cancel',
                    attrs: {
                        text: true,
                        color: 'grey'
                    },
                    on: {
                        click: () => { this.$refs.dialogcloseTabAlert.hide(); }
                    }
                },
                {
                    label: 'Close',
                    attrs: {
                        text: true,
                        color: 'error'
                    },
                    on: {
                        click: () => {
                            this.closeTab(alertedFileIndex);
                            this.$refs.dialogcloseTabAlert.hide();
                        }
                    }
                },
                {
                    label: 'Save and Close',
                    attrs: {
                        text: true,
                        color: 'indigo'
                    },
                    on: {
                        click: () => {
                            this.saveFile().then(() => {
                                this.closeTab(alertedFileIndex);
                                this.$refs.dialogcloseTabAlert.hide();
                            });
                        }
                    }
                },
            ]"
        >
            <template #title>
                Close "{{ files[alertedFileIndex] ? files[alertedFileIndex].label : '' }}"?
            </template>
            <template #body>
                <v-card-text>
                    Do you really close this file? Some last changes may be lost.
                </v-card-text>
            </template>
        </tutti-dialog>
        
        <tutti-dialog
            max-width="800"
            transition="dialog-bottom-transition"
            ref="dialogFileExplorer"
        >
            <template #title>
                Open File (Project: {{ projectName }})
                <v-spacer></v-spacer>
                <v-icon @click="$refs.dialogFileExplorer.hide()">mdi-close</v-icon>
            </template>
            <template #body>
                <div style="height:600px">
                    <file-explorer
                        ref="fileExplorer"
                        :client="client"
                        :source="fsSource"
                        :root-path="fileExplorerRootPath"
                        @open="openFile"
                    >
                        <template #label-append="{ active, item, renameFile, deleteFile }">
                            <v-icon v-if="active" small class="mx-1" @click.stop="renameFile(item, item.name)">mdi-pencil</v-icon>
                            <v-icon v-if="active" small class="mx-1" @click.stop="deleteFile(item)">mdi-delete</v-icon>
                        </template>
                    </file-explorer>
                </div>
            </template>
        </tutti-dialog>
    </v-app>
</template>
<script>
import draggable from 'vuedraggable'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import TuttiDialog from '@/components/ui/TuttiDialog'
import FileExplorer from '@/components/ui/FileExplorer'
import { codemirror } from 'vue-codemirror'
import cmOptionsDefault from '@/lib/cm-options-default.js'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/vue/vue';
import 'codemirror/mode/python/python';

const themes = {
    'base16-light': 'base16-light.css',
    'base16-dark': 'base16-dark.css',
    'solarized light': 'solarized.css',
    'paraiso-light': 'paraiso-light.css',
    'paraiso-dark': 'paraiso-dark.css',
    'monokai': 'monokai.css',
    'ambiance': 'ambiance.css',
    'cobalt': 'cobalt.css',
    'rubyblue': 'rubyblue.css',
    'mbo': 'mbo.css',
    'hopscotch': 'hopscotch.css',
    'lesser-dark': 'lesser-dark.css',
}
const firstTheme = Object.keys(themes)[0];

const cmOptionsDummyFilePath = '__cmOptions__.js';
const fsSource = 'projects';

export default {
    components: {
        draggable,
        codemirror,
        TuttiSnackbar,
        TuttiDialog,
        FileExplorer
    },
    data() {
        return {
            fsSource,
            menuOpen: {
                File: false,
                Edit: false,
            },
            files: [],
            selectedTabIndex: 0,
            drag: false,
            cmThemeItems: Object.keys(themes),
            cmOptions: {},
            cmTheme: undefined,
            cmMode: undefined,
            alertedFileIndex: null
        }
    },
    props: {
        client: { default: null, },
        projectName: { type: String, default: '', },
        templateName: { type: String, default: '', },
        fileExplorerRootPath: { type: String, },
        openFilePaths: { type: Array, default: () => ([]) }
    },
    computed: {
        selectedFile() {
            return (this.files.length > 0 && this.selectedTabIndex >= 0) ? this.files[this.selectedTabIndex] : {};
        },
        cmAllOptions() {
            let options = { ...this.cmOptions };
            if(!this.cmOptions.mode && this.cmMode) options.mode = this.cmMode;
            if(!this.cmOptions.theme && this.cmTheme) options.theme = this.cmTheme;
            return options;
        },
        selectedCM() {
            return this.$refs[`cm-${this.files[this.selectedTabIndex].path}`][0].codemirror;
        }
    },
    methods: {
        /* codemirror */
        initCM() {
            this.loadCMOptions();
            this.setCMLanguageMode(this.selectedFile);
        },
        getCMOptionsString() {
            let cmOptions = window.localStorage.getItem('tuttiCMOptions');
            if(cmOptions === null) {
                cmOptions = cmOptionsDefault;
                window.localStorage.setItem('tuttiCMOptions', cmOptions);
            }
            return cmOptions;
        },
        loadCMOptions() {
            const cmOptions = this.getCMOptionsString();
            this.cmOptions = Function(`
                    var window = {};
                    var document = {};
                    const saveFile = arguments[0];
                    ${cmOptions}`
                )(this.saveFile);
            this.setCMTheme(window.localStorage.getItem('tuttiTemplateEditorTheme') || firstTheme);

        },
        saveCMOptions(file) {
            window.localStorage.setItem('tuttiCMOptions', file.code);
        },
        setCMLanguageMode(file) {
            if(file.path) {
                const ext = file.path.split('.').slice(-1).pop();
                if(ext === 'vue') {
                    this.cmMode = 'text/x-vue';
                } else if(ext === 'py') {
                    this.cmMode = 'text/x-python';
                } else if(ext === 'js') {
                    this.cmMode = 'text/javascript';
                } else if(ext === 'ts') {
                    this.cmMode = 'text/typescript';
                } else if(ext === 'css') {
                    this.cmMode = 'text/css';
                } else if(ext === 'html') {
                    this.cmMode = 'text/html';
                } else {
                    this.cmMode = undefined;
                }
            }
        },
        execCMCommand(command) {
            this.selectedCM.execCommand(command);
        },
        setCMTheme(themeKey) {
            import(`codemirror/theme/${themes[themeKey]}`);
            this.cmTheme = themeKey;
            window.localStorage.setItem('tuttiTemplateEditorTheme', themeKey);
        },

        /* file */
        async openFile(path) {
            const index = this.files.findIndex(f => f.path === path);
            if(index > -1) {
                this.$refs.dialogFileExplorer.hide();
                this.focusTab(index);
            } else {
                try {
                    const code = await this.client.resource.loadFileFromProject({ path });
                    const relativePath = path.substring(path.indexOf(this.fileExplorerRootPath)+this.fileExplorerRootPath.length + 1);
                    this.files.push({
                        code,
                        path: path,
                        label: relativePath,
                        saved: true,
                    });
                    this.$refs.dialogFileExplorer.hide();
                    this.focusTab(this.files.length-1);
                } catch (e) {
                    alert(`Failed to load the file: ${JSON.stringify(e.details.stacktrace)}`)
                    console.error(e.details);
                }
            }
        },
        async saveFile() {
            const file = this.selectedFile;
            if(file.path === cmOptionsDummyFilePath) {
                this.saveCMOptions(file);
                this.initCM();
            } else {
                const splittedPath = file.path.split('/');
                const current_path = splittedPath.slice(0,-1).join('/');
                const name = splittedPath[splittedPath.length-1];
                const files = [{ path: name, data: file.code }]

                try {
                    await this.client.resource.saveFilesToProject({ current_path, files });
                } catch (e) {
                    console.error(e);
                    alert(`Failed to save file: ${JSON.stringify(e.details.stacktrace)}`);
                    console.error(e.details);
                    return;
                }
            }
            this.$refs.snackbar.show('black', 'Saving file...', 1000);
            this.files[this.selectedTabIndex].saved = true;
        },

        /* tabs */
        focusTab(index) {
            this.selectedTabIndex = index;
        },
        openSettingsTab() {
            let cmOptions = this.getCMOptionsString();
            this.files.push({
                path: cmOptionsDummyFilePath,
                label: 'Settings',
                code: cmOptions,
                saved: true
            });
            this.selectedTabIndex = this.files.length-1;
        },
        confirmOrCloseTab(index) {
            if(this.files[index].saved) this.closeTab(index);
            else this.showcloseTabAlert(index);
        },
        closeTab(index) {
            if(index === this.files.length-1) {
                this.selectedTabIndex = index-1;
            }
            this.files.splice(index, 1);
            if(this.files.length === 0) {
                window.close();
            }
        },
        showcloseTabAlert(index) {
            this.alertedFileIndex = index;
            this.$refs.dialogcloseTabAlert.show();
        }
    },
    watch: {
        selectedFile(file) {
            this.setCMLanguageMode(file);
        }
    },
    async created() {
        this.initCM();

        if(window.opener) {
            window.resizeTo(window.screen.availWidth/2, window.screen.availHeight);
        }
        window.onbeforeunload = () => {
            return this.files.length > 0 ? 'Are you sure you want to leave?' : undefined;
        }

        this.client.invokeOnOpen(async () => {
            const accessToken = window.localStorage.getItem('tuttiConsoleAccessToken');
            let signInSuccess = false;
            if(accessToken) {
                await this.client.resource.signIn({ access_token: accessToken });
                signInSuccess = true;

                this.openFilePaths.forEach(async (paths) => {
                    await this.openFile(paths);
                });
            }
            if(!signInSuccess) {
                alert('You need to sign into Tutti Console first!');
            }
        });
    }
  };
</script>
<style>
.v-tab {
    max-width: max-content!important;
}
.v-list {
    padding: 0px 0;
    font-size: 14px;
}
.v-list-item {
    min-height: 32px!important;
}
.v-toolbar__content {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}
.CodeMirror {
    height: 100%;
    border: 1px solid #ccc;
    font-size: 14px;
}
#webpack-dev-server-client-overlay {
    display: none!important;
}
</style>
