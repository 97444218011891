export default
`/* Tutti's code editor is powered by CodeMirror 5. For the list of configuration options, see:
 * https://codemirror.net/5/doc/manual.html#config
 *
 * A method named "saveFile" which can be used in extraKeys bindings, which saves the current file in the opened tab.
 */

return {
    tabSize: 4,
    lineNumbers: true,
    line: true,
    smartIndent: true,
    lineWrapping: true,
    indentUnit: 4,
    electricChars: false,
    extraKeys: {
        "Tab": function(cm) {
            var spaces = Array(cm.getOption('indentUnit') + 1).join(' ');
            cm.replaceSelection(spaces);
        },
        "Shift-Tab": 'indentLess',
        "Ctrl-S": saveFile,
        "Cmd-S": saveFile,
        "Cmd-Enter": saveFile,
    }
}`