var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "pa-0",
          attrs: {
            app: "",
            fixed: "",
            height: "30",
            elevation: "4",
            "extension-height": "30",
          },
          scopedSlots: _vm._u([
            {
              key: "extension",
              fn: function () {
                return [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "show-arrows": "",
                        color: "indigo",
                        height: "30",
                      },
                      model: {
                        value: _vm.selectedTabIndex,
                        callback: function ($$v) {
                          _vm.selectedTabIndex = $$v
                        },
                        expression: "selectedTabIndex",
                      },
                    },
                    [
                      _c("v-tabs-slider", { attrs: { color: "indigo" } }),
                      _c(
                        "draggable",
                        {
                          staticClass: "d-flex",
                          attrs: { value: _vm.files },
                          on: {
                            change: (e) => {
                              _vm.focusTab(e.moved.newIndex)
                            },
                            input: (l) => {
                              _vm.files = l
                            },
                          },
                        },
                        _vm._l(_vm.files, function (file, i) {
                          return _c(
                            "v-tab",
                            {
                              key: `file-tab-${i}`,
                              staticStyle: { cursor: "initial" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex",
                                  style: {
                                    minWidth: "100px",
                                    maxWidth:
                                      _vm.selectedTabIndex !== i
                                        ? "150px"
                                        : undefined,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "text-none": true,
                                        "text-truncate":
                                          _vm.selectedTabIndex !== i,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(file.label) + " ")]
                                  ),
                                  _c("v-spacer"),
                                  _c("v-hover", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ hover }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.confirmOrCloseTab(
                                                        i
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        file.saved || hover
                                                          ? "mdi-close"
                                                          : "mdi-circle-medium"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-toolbar-items",
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", rounded: "0" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ attrs, on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-none",
                                  attrs: { text: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("File")]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menuOpen.File,
                    callback: function ($$v) {
                      _vm.$set(_vm.menuOpen, "File", $$v)
                    },
                    expression: "menuOpen.File",
                  },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$refs.dialogFileExplorer.show()
                            },
                          },
                        },
                        [_vm._v("Open...")]
                      ),
                      _c("v-list-item", { on: { click: _vm.saveFile } }, [
                        _vm._v("Save"),
                      ]),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.confirmOrCloseTab(_vm.selectedTabIndex)
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", rounded: "0" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ attrs, on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "text-none",
                                  attrs: { text: "" },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("Edit")]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menuOpen.Edit,
                    callback: function ($$v) {
                      _vm.$set(_vm.menuOpen, "Edit", $$v)
                    },
                    expression: "menuOpen.Edit",
                  },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.execCMCommand("undo")
                            },
                          },
                        },
                        [_vm._v("Undo")]
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.execCMCommand("redo")
                            },
                          },
                        },
                        [_vm._v("Redo")]
                      ),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.execCMCommand("selectAll")
                            },
                          },
                        },
                        [_vm._v("Select All")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-x": "",
                            "open-on-hover": "",
                            rounded: "0",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ attrs, on }) {
                                return [
                                  _c(
                                    "v-list-item",
                                    _vm._g(
                                      _vm._b(
                                        { staticStyle: { cursor: "default" } },
                                        "v-list-item",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "pa-0" },
                                        [_vm._v("Color Theme")]
                                      ),
                                      _c(
                                        "v-list-item-action",
                                        { staticClass: "my-0" },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-chevron-right")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            _vm._l(_vm.cmThemeItems, function (themeKey, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: `theme-list-${i}`,
                                  on: {
                                    click: function ($event) {
                                      _vm.setCMTheme(themeKey)
                                      _vm.menuOpen.Edit = false
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "pa-0" },
                                    [_vm._v(_vm._s(themeKey))]
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    { staticClass: "ma-0" },
                                    [
                                      _vm.cmAllOptions.theme === themeKey
                                        ? _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-check")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.openSettingsTab } },
                        [_vm._v("More Settings...")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c("div", [_vm._v("Project: " + _vm._s(_vm.projectName))]),
        ],
        1
      ),
      _c(
        "v-main",
        _vm._l(_vm.files, function (file, i) {
          return _c("codemirror", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedTabIndex == i,
                expression: "selectedTabIndex==i",
              },
            ],
            key: `cm-${file.path}`,
            ref: `cm-${file.path}`,
            refInFor: true,
            attrs: { options: _vm.cmAllOptions },
            on: {
              input: function ($event) {
                _vm.files[_vm.selectedTabIndex].saved = false
              },
              ready: (cm) => {
                cm.doc.clearHistory()
                cm.refresh()
              },
            },
            model: {
              value: file.code,
              callback: function ($$v) {
                _vm.$set(file, "code", $$v)
              },
              expression: "file.code",
            },
          })
        }),
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
      _c("tutti-dialog", {
        ref: "dialogcloseTabAlert",
        attrs: {
          "max-width": "600",
          buttons: [
            {
              label: "Cancel",
              attrs: {
                text: true,
                color: "grey",
              },
              on: {
                click: () => {
                  this.$refs.dialogcloseTabAlert.hide()
                },
              },
            },
            {
              label: "Close",
              attrs: {
                text: true,
                color: "error",
              },
              on: {
                click: () => {
                  this.closeTab(_vm.alertedFileIndex)
                  this.$refs.dialogcloseTabAlert.hide()
                },
              },
            },
            {
              label: "Save and Close",
              attrs: {
                text: true,
                color: "indigo",
              },
              on: {
                click: () => {
                  this.saveFile().then(() => {
                    this.closeTab(_vm.alertedFileIndex)
                    this.$refs.dialogcloseTabAlert.hide()
                  })
                },
              },
            },
          ],
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  ' Close "' +
                    _vm._s(
                      _vm.files[_vm.alertedFileIndex]
                        ? _vm.files[_vm.alertedFileIndex].label
                        : ""
                    ) +
                    '"? '
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " Do you really close this file? Some last changes may be lost. "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog", {
        ref: "dialogFileExplorer",
        attrs: { "max-width": "800", transition: "dialog-bottom-transition" },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " Open File (Project: " + _vm._s(_vm.projectName) + ") "
                ),
                _c("v-spacer"),
                _c(
                  "v-icon",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$refs.dialogFileExplorer.hide()
                      },
                    },
                  },
                  [_vm._v("mdi-close")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticStyle: { height: "600px" } },
                  [
                    _c("file-explorer", {
                      ref: "fileExplorer",
                      attrs: {
                        client: _vm.client,
                        source: _vm.fsSource,
                        "root-path": _vm.fileExplorerRootPath,
                      },
                      on: { open: _vm.openFile },
                      scopedSlots: _vm._u([
                        {
                          key: "label-append",
                          fn: function ({
                            active,
                            item,
                            renameFile,
                            deleteFile,
                          }) {
                            return [
                              active
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return renameFile(item, item.name)
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-pencil")]
                                  )
                                : _vm._e(),
                              active
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return deleteFile(item)
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-delete")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }