var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.openFilePaths.length > 0
    ? _c("code-editor", {
        attrs: {
          client: _vm.client,
          "project-name": _vm.projectName,
          "template-name": _vm.templateName,
          "file-explorer-root-path": `${_vm.projectName}/${_vm.mode}`,
          "open-file-paths": _vm.openFilePaths,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }